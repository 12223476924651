import bgMerged from "../assets/images/road-map-tree1-merged.png"

import logoGuardian from "../assets/images/logo-guardian-1.png"
import logoFallen from "../assets/images/logo-fallen-1.png"
import logoPixel from "../assets/images/logo-pixel-1.png"
import { Container } from "react-bootstrap"
import DrawingConcept from "./DrawingConcept"
import DownArrow from "./DownArrow"

const RoadmapDesktop = () => {
    return (
        <section className="roadmap-section d-none d-lg-block">
            <div className="roadmap ">

                <div className="text-center">

                <div className="section-title">ROADMAP</div>
                <div className="mb-5">*note: Roadmap is subject to change over the course of launch </div>
                </div>

                <div className="grid-parent">
                    <div className="grid-child-curve">
                        <img src={bgMerged} className="" />
                    </div>

                    <div className="grid-child-text">


                        <div className="grid-parent-roadmap-parts mx-2">
                            <div className="grid-child-roadmap-part-1 d-flex align-items-center gap-2">
                                <h2>1</h2>
                                <div>
                                    <h3>GUARDIAN ANGLES</h3>
                                    <p>
                                        Not wicked, nor temptatious,<br />
                                        these Guardian Angels are truly gracious.<br />
                                        Once their charm is perceived, it becomes contagious,<br />
                                        spreading beauty and wonder in ways that are outrageous.<br />
                                        <br />
                                        With a heart and soul, just like you and me,<br />
                                        These celestial beings watch over us endlessly.<br />
                                        You will feel their presence, this is a guarantee, guiding us towards a brighter destiny.<br />
                                        <br />
                                        Every being is extraordinary, this is oh so true,<br />
                                        and these Angels are no exception, that much is due.<br />
                                        So let us thank our Guardian Angels, because they have chosen you, to bless with their grace and keep us safe and true.<br />
                                    </p>

                                        <p>
                                            <br />
                                            Guardian Angels is the first collection by Celestial Angels NFT<br />
                                            It consists of 2,450 unique angels with over 13 completely randomized traits that depict an ethereal aura.<br />
                                            In this collection, there are only 7 legendary 1/1 Angels, a number that symbolizes their completion and perfection!
                                        </p>
                                    <p>
                                        <br />

                                        This is a free-to-mint collection!
                                    </p>

                                </div>
                            </div>

                            <div className="grid-child-roadmap-part-2 d-flex align-items-center" >
                                <h2>2</h2>
                                <div>
                                    <h3>Infernal Transmutation Token</h3>
                                    <p>
                                        In the realm of light, where pure hearts dwell, Lies a secret token, an enigmatic spell.<br /><br />
                                        The Infernal Transmutation Token, in all its might, Holds the power to turn day into darkest night.<br />
                                        Once a guardian, noble and true, An angel of light, in skies so blue.<br /><br />
                                        But with this token, a transformation begins, A new fallen chapter, where darkness wins.<br />
                                        As the token burns, a tale untold, The angel's heart turns wicked and cold.<br /><br />
                                        From their celestial throne, they now descend, A twisted path, where grace and malice blend.<br /><br />
                                        The Infernal Transmutation Token, a key to the abyss, A harrowing journey, impossible to dismiss.<br />
                                        In the hands of the brave, the righteous and the wise, A Fallen Angel emerges, and a Guardian's demise.<br />

                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="grid-child-text grid-child-roadmap-part-3 d-flex align-items-center">
                        <h2>3</h2>
                        <div>
                            <h3>Fallen Angels</h3>
                            <p>
                                Guarding and protecting?, that is not their style<br />
                                They don't seem to be kind hearted, or virtuous like Gabriel and Mika’il<br />
                                <br />
                                They were thrown out of heaven, you see.. they have no soul<br />
                                Their intention is to bring corruption, and spread it like a mole<br />
                                <br />
                                They go by many names, some call them Jinn<br />
                                Pure evil regardless, because the fallen incite you to sin!<br />

                            </p>
                            <p>
                                Fallen Angels is the second collection by Celestial Angels NFT<br />
                                It consists of 2,450 unique angels with over 13 completely randomized traits that depicts the<br />
                                eternal abyss of the netherworld.<br />
                                In this collection, there are only 7 legendary 1/1 Angels, a number that illustrates the 7 deadly sins!<br />

                            </p>
                            <p>
                                <br />
                                This is a burn-to-mint collection!
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div id="drawing" className="position-relative" style={{
                 paddingBottom:"5rem"}}>
                <DrawingConcept />
                <DownArrow to="team-section" />
            </div>

           

        </section>
    )
}

const RoadmapMobile = () => {
    return (
        <div className="roadmap-section-mobile d-lg-none">
            <Container>
                <h1 className="text-center section-title mb-2">ROADMAP</h1>
                <div className="logo-guardian mb-5">
                    <img src={logoGuardian} />
                    <div>

                        <div className="d-flex align-items-baseline gap-3">
                            <h1>1</h1>
                            <h3>GUARDIAN ANGLES</h3>
                        </div>
                        <p>
                            Not wicked, nor temptatious,<br />
                            these Guardian Angels are truly gracious.<br />
                            Once their charm is perceived, it becomes contagious,<br />
                            spreading beauty and wonder in ways that are outrageous.<br />
                            <br />
                            With a heart and soul, just like you and me,<br />
                            These celestial beings watch over us endlessly.<br />
                            You will feel their presence, this is a guarantee, guiding us towards a brighter destiny.<br />
                            <br />
                            Every being is extraordinary, this is oh so true,<br />
                            and these Angels are no exception, that much is due.<br />
                            So let us thank our Guardian Angels, because they have chosen you, to bless with their grace and keep us safe and true.<br />
                        </p>

                        <p>
                            <br />
                            Guardian Angels is the first collection by Celestial Angels NFT<br />
                            It consists of 2,450 unique angels with over 13 completely randomized traits that depict an ethereal aura.<br />
                            In this collection, there are only 7 legendary 1/1 Angels, a number that symbolizes their completion and perfection!
                        </p>
                        <p>
                            <br />

                            This is a free-to-mint collection!
                        </p>
                    </div>
                </div>

                <div className="logo-fallen text-end mb-5">
                    <img src={logoFallen} />
                    <div>
                        <div className="d-flex align-items-baseline justify-content-end gap-3">
                            <h1>2</h1>
                            <h3>Infernal Transmutation Token</h3>
                        </div>
                        <p>
                            In the realm of light, where pure hearts dwell, Lies a secret token, an enigmatic spell.<br /><br />
                            The Infernal Transmutation Token, in all its might, Holds the power to turn day into darkest night.<br />
                            Once a guardian, noble and true, An angel of light, in skies so blue.<br /><br />
                            But with this token, a transformation begins, A new fallen chapter, where darkness wins.<br />
                            As the token burns, a tale untold, The angel's heart turns wicked and cold.<br /><br />
                            From their celestial throne, they now descend, A twisted path, where grace and malice blend.<br /><br />
                            The Infernal Transmutation Token, a key to the abyss, A harrowing journey, impossible to dismiss.<br />
                            In the hands of the brave, the righteous and the wise, A Fallen Angel emerges, and a Guardian's demise.<br />

                        </p>
                    </div>
                </div>

                <div className="logo-pixel mb-5">
                    <img src={logoPixel} />
                    <div>
                        <div className="d-flex align-items-baseline gap-3">
                            <h1>3</h1>
                            <h3>Fallen Angels</h3>
                        </div>
                        <p>
                            Guarding and protecting?, that is not their style<br />
                            They don't seem to be kind hearted, or virtuous like Gabriel and Mika’il<br />
                            <br />
                            They were thrown out of heaven, you see.. they have no soul<br />
                            Their intention is to bring corruption, and spread it like a mole<br />
                            <br />
                            They go by many names, some call them Jinn<br />
                            Pure evil regardless, because the fallen incite you to sin!<br />

                        </p>
                        <p>
                            Fallen Angels is the second collection by Celestial Angels NFT<br />
                            It consists of 2,450 unique angels with over 13 completely randomized traits that depicts the<br />
                            eternal abyss of the netherworld.<br />
                            In this collection, there are only 7 legendary 1/1 Angels, a number that illustrates the 7 deadly sins!<br />

                        </p>
                        <p>
                            <br />
                            This is a burn-to-mint collection!
                        </p>
                    </div>
                </div>
            </Container>
            <div id="drawing-concept-section" className="position-relative" style={{
                 paddingBottom:"5rem"}}>
                <DrawingConcept mobile={true}/>
                <DownArrow to="team-section" />
            </div>
        </div>
    )
}

const Roadmap = () => {
    return (
        <>
            <RoadmapMobile />
            <RoadmapDesktop />
        </>
    )
}

export default Roadmap;