import logo from './logo.svg';
import './App.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fontawesome/css/all.min.css';
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom';
import Home from './screens/Home';
import MyNfts from './components/MyNfts';
import PublicSale from './screens/PublicSale';
import PrivateSale from './screens/PrivateSale';
import Admin from './screens/admin/Admin';
import TimerSetting from './screens/admin/TimerSetting';
import Whitelist from './screens/admin/Whitelist';
import Reveal from './screens/admin/Reveal';
import Sale from './screens/admin/Sale';



function App() {
  return (

    <Router>


      <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/public-sale/:id" element={<PublicSale/>} />
          <Route path="/private-sale/:id" element={<PrivateSale/>} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/timer-setting" element={<TimerSetting />} />
          <Route path="/admin/whitelist" element={<Whitelist />} />
          <Route path="/admin/reveal" element={<Reveal />} />
          <Route path="/admin/Sale" element={<Sale />} />
          <Route path="/nft" element={<MyNfts />} />
      </Routes>

    </Router>
  );
}

export default App;
