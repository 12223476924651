import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { injectedConnector } from "../../utils/connectors"
import { connectWallet } from "../../utils/connectWallet";
import Header from "../../components/Header"
import loader from "../../assets/images/loader.gif"
import { Col, Container, Row, Accordion } from "react-bootstrap";
import axios from "axios";

function TimerSetting() {
    const [loaded, setLoaded] = useState(false)
    const [dateTime, setDateTime] = useState('')
    const [nftType, setNftType] = useState('0')
    const [saleType, setSaleType] = useState('0')
    const [timerType, setTimerType] = useState(0)
    const [message, setMessage] = useState('')
    
    const {
        connector,
        library,
        account,
        chainId,
        activate,
        deactivate,
        active,
        errorWeb3Modal,
        active: networkActive, error: networkError, activate: activateNetwork
    } = useWeb3React();
    
    useEffect(() => {
        injectedConnector
            .isAuthorized()
            .then((isAuthorized) => {
                setLoaded(true)
                if (isAuthorized && !networkActive && !networkError) {
                    activateNetwork(injectedConnector)
                }
            })
            .catch(() => {
                setLoaded(true)
            })
    }, [activateNetwork, networkActive, networkError])

    var myVar;

    function myFunction() {
        myVar = setTimeout(showPage, 3000);
    }

    function showPage() {
        document.getElementById("loader").style.display = "none";
        document.getElementById("myDiv").style.display = "block";
    }

    myFunction();
    const url = 'http://localhost/celestial/public/';
    useEffect(async ()=>{
    await axios.get(url+'timer-setting?nft_type='+nftType+'&sale_type='+saleType).then((res)=>{
        setDateTime(res.data.data.date_time)
        setTimerType(res.data.data.timer_type)
    })
    },[nftType,saleType]);

    const updateTimer = async e =>{
        await axios.post(url+'update-timer-setting', {
          nft_type:nftType,
          sale_type:saleType,
          date_time:dateTime,
          timer_type:timerType
        })
      .then(function (response) {
          setMessage(response.data.message);
          console.log(response.data.message)
      })
      .catch(function (error) {
          console.log(error.message)
    });
    }
    return (

        <>

            <div id="loader">
                <img src={loader} />
            </div>

            <div style={{ display: "none" }} id="myDiv" class="animate-bottom">

                <Header />

                <section className="section w-100">
                    <Container>

                        {/* <div className="head-btn">
                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">SetNftaddress</button>
                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Start Auction</button>
                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Bid</button>
                    </div> */}
                        <Row >
                            <Col lg={4} className="m-auto">
                                <div className="admin-page">
                                    <h3 className="admin-title">Timer</h3>
                                    <div className="form-group">
                                        <label for="datetime">Date</label>
                                        <input className="form-control" type="datetime-local" value={dateTime} onChange={(e)=>setDateTime(e.target.value)}/>
                                    </div>
                                    <div className="form-group">
                                        <label for="datetime">NFT Type</label>
                                        <select className="form-control" value={nftType} onChange={(e)=>setNftType(e.target.value)}>
                                            <option value={0}>Fallen One</option>
                                            <option value={1}>Guardian One</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="datetime">Sale Type</label>
                                        <select className="form-control" value={saleType} onChange={(e)=>setSaleType(e.target.value)}>
                                            <option value={0}>PublicSale</option>
                                            <option value={1}>PrivateSale</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label for="datetime">Timer Type</label>
                                        <select className="form-control" value={timerType} onChange={(e)=>setTimerType(e.target.value)}>
                                            <option value={0}>Start</option>
                                            <option value={1}>Stop</option>
                                        </select>
                                    </div>
                                        {
                                            message ? <span className="text-success">
                                                {message}
                                            </span>:''
                                        }
                                    <div className="admin-btn-group">
                                       
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3" onClick={(e)=>updateTimer()}>Submit</button>
                                    </div>

                                </div>
                            </Col>
                        </Row>


                    </Container>
                </section>

                {/* <div id="footer-section" className="position-relative">
                    <Footer />
                </div> */}

            </div>

        </>

    )
}

export default TimerSetting;