import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { injectedConnector } from "../utils/connectors"
import { connectWallet } from "../utils/connectWallet";
import Logo from "../assets/images/logo 2.png";
import opensea from "../assets/images/opensea.svg";
import Header from "../components/Header"
import Team from "../components/Team";
import FAQs from "../components/FAQs";
import DownArrow from "../components/DownArrow";
import JoinUs from "../components/JoinUs";
import DrawingConcept from "../components/DrawingConcept";
import About from "../components/About";
import Roadmap from "../components/Roadmap";
import Footer from "../components/Footer"
import Collection from "../components/Collection";
import loader from "../assets/images/loader.gif"
import Subtraction from "../assets/images/subtraction.png"
import Addition from "../assets/images/addition.png"
import MintButton from "../assets/images/mint-btn.png"
import { Col, Container, Row, Accordion } from "react-bootstrap";
import Timer from "../components/Timer";
import { useParams } from "react-router-dom";

import { nFTCrowdsale_addr, nFT1_addr, nFT2_addr } from "../contract/addresses";
import NFTCrowdsaleAbi from "../contract/NFTCrowdsale.json"
import { ethers, BigNumber } from 'ethers';
import Web3Modal from 'web3modal';

function PublicSale() {

    const [loaded, setLoaded] = useState(false)
    const {id} = useParams()
    const {
        connector,
        library,
        account,
        chainId,
        activate,
        deactivate,
        active,
        errorWeb3Modal,
        active: networkActive, error: networkError, activate: activateNetwork
    } = useWeb3React();

    const loadProvider = async () => {
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            return provider.getSigner();
        } catch (e) {
            console.log("loadProvider default: ", e);
        }
    };


    useEffect(() => {
        console.log("pageID",id)
        injectedConnector
            .isAuthorized()
            .then((isAuthorized) => {
                setLoaded(true)
                if (isAuthorized && !networkActive && !networkError) {
                    activateNetwork(injectedConnector)
                }
               
            })
            .catch(() => {
                setLoaded(true)
            })
    }, [activateNetwork, networkActive, networkError])

    var myVar;

    function myFunction() {
        myVar = setTimeout(showPage, 3000);
    }

    function showPage() {
        document.getElementById("loader").style.display = "none";
        document.getElementById("myDiv").style.display = "block";
    }

    const nftType = (id)=>{
        if(id > 1){
            return 1;
        }else{
            return 0;
        }
    }

    const buy = async()=> {
        let signer = await loadProvider()
        let NFTCrowdsaleContract = new ethers.Contract(nFTCrowdsale_addr, NFTCrowdsaleAbi, signer)
        let _value = await NFTCrowdsaleContract.Sale_price(id)
        let tx = await NFTCrowdsaleContract.buyNFT(nftType(id) , id , {value:_value})
        await tx.wait()

    }


    myFunction();
    return (

        <>

            <div id="loader">
                <img src={loader} />
            </div>

            <div style={{ display: "none" }} id="myDiv" class="animate-bottom">

                <Header />

                <section className="section1 mint-100-vh">

                    <div className="">

{/* 
                            <div className="main-title col-xl-5 col-lg-7 col-md-9 col-12">

                                <div className="opensea justify-content-center flex-column flex-md-row">


                                    <a href="https://opensea.io/" target="_blank" className="primary-btn custom-btn image-btn">
                                        <img src={opensea} /><span className="">Open Sea</span>
                                    </a>
                                </div>


                            </div> */}

                            <div class="work-arrow mint-arrow-section">

                                <div className="mint-box">
                                    <Timer nft_type={id} sale_type={0}/> 
                                
                                    <div className="mint-body">
                                        {active ? <>
                                            <h3>You may now start minting.</h3>
                                            <div className="mint-qty">
                                                <div className="position-relative">
                                                    <img src={Subtraction} className="minus" />
                                                    <input className="qty" readOnly type="text" value='1' />
                                                    <img src={Addition} className="plus" />
                                                </div>
                                            </div>
                                            <h3>Total : 2.00 ETH</h3>
                                            <img src={MintButton} className="mint-btn" onClick={buy}/>
                                        </> : <>
                                            <h3>MINTING IS LIVE...</h3>
                                            <h4>Connect your wallet to start minting</h4>
                                        </>
                                        }

                                    </div>
                                    <div className="mint-footer">
                                        {active
                                            ? (
                                                <div className="group-btn-flex">
                                                    <button className="btn btn-primary btn-wallet">Wallect Connected</button>
                                                    <button className="btn btn-primary btn-wallet mt-1 mb-1">wallet address : {account.substring(0, 4) + '...' + account.substring(4, 8)}</button>
                                                </div>
                                            ) : <button onClick={(e) => {
                                                connectWallet(activate, "Error");
                                                e.preventDefault()
                                            }} className="btn btn-primary btn-wallet">CONNECT YOUR WALLET</button>
                                        }
                                    </div>
                                </div>

                            </div>


                    </div>



                </section>

                {/* <div id="footer-section" className="position-relative">
                    <Footer />
                </div> */}

            </div>

        </>

    )
}

export default PublicSale;