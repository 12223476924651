import axios from "axios";
import { useEffect, useState } from "react";
import Countdown from 'react-countdown';

function Timer(props)
{
    const [timer,setTimer] = useState([])
    const url = 'http://localhost/celestial/public/';
    useEffect(async ()=>{
    await axios.get(url+'timer-setting?nft_type='+props.nft_type+'&sale_type='+props.sale_type).then((res)=>{
        setTimer(res.data.data)
    })
    },[]);

    const renderer = ({ days,hours, minutes, seconds, completed }) => {
        if (timer.timer_type == '0' || completed) {
            // Render a completed state
            return <div className="timer-box">
                    <div className="inner">
                        <span>00</span>
                    </div>
                    <div className="inner">
                        <span>00</span>

                    </div>
                    <div className="inner">
                        <span>00</span>

                    </div>
                </div>;

        } else {
            // Render a countdown
            return <div className="timer-box">
                
                    <div className="inner">
                        <span>{hours}</span>

                    </div>
                    <div className="inner">
                        <span>{minutes}</span>

                    </div>
                    <div className="inner">
                        <span>{seconds}</span>
                    </div>
                </div>;
        }
        };

        return <>
         {
            timer.date_time ? <Countdown
            date={timer.date_time}
            renderer={renderer}
        />:''
        }
        </>
}

export default Timer