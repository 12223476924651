import { Row, Col, Container, Button } from "react-bootstrap";
import discordTransparent from "../assets/images/discord-transparent.png"
import join from "../assets/images/join.png"
import joinVideo from "../assets/images/join-video.mp4"


const JoinUs = (props) => {
    return (
        <section className="joinus-section">
            <div className="joinus pb-5 pb-md-0">
                <Row className="mx-0">
                    <Col md={6} className="px-0 mobile-order-2">
                        {/* <img src={joinVideo} /> */}
                        <video className="w-100 h-100" src={joinVideo} loop={true} autoPlay={true} muted={true} controls={false} playsInline>

                        </video>
                    </Col>
                    <Col sm={12} md={6} className="faqs join-text mobile-order-1">
                    <h1 className="text-center mb-5">NEWS</h1>
                        <div className="d-flex align-items-center justify-content-center h-100">
                            <div className="box col-12 col-lg-8">
                                <p className="description">For the latest celestial scoops, vital announcements, and thrilling revelations, ascend to our official Twitter realm. Make sure to grace us with a follow, ensuring you remain ever-vigilant and in the know.
                                    Stay connected with our ethereal community and never miss a beat as we embark on this cosmic adventure together. Let the echoes of our Tweets guide you through the mysteries of the Celestial Angels, and may fortune favor you on this otherworldly odyssey.
                                    Follow us on Twitter now and witness the celestial chronicles unfold before your very eyes
                                </p>
                                <div className="text-center">
                                    <a target="_blank" href="https://twitter.com/CAngelsNFT" className="btn btn-primary btn-wallet join-discord-btn"><i className="fa-brands fa-twitter"></i><span>FOLLOW OUR TWITTER</span></a>

                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default JoinUs;