import {Navbar, Container, Nav, NavDropdown, Button, Offcanvas} from "react-bootstrap";
import Logo from "../assets/images/logo.png";
import opensea from "../assets/images/opensea.png";
import {useWeb3React} from "@web3-react/core";
import {useState, useEffect} from "react";
import {Link} from "react-router-dom";

function Header() {

    const {
        connector,
        library,
        account,
        chainId,
        activate,
        deactivate,
        active,
        errorWeb3Modal,
        active: networkActive, error: networkError, activate: activateNetwork
    } = useWeb3React();

    // const [navbar, setNavbar] = useState(false);


    // useEffect(() => {
    //     window.addEventListener("scroll", () => {
    //       if (window.pageYOffset > 80) {
    //         setNavbar(true);
    //       } else {
    //         setNavbar(false);
    //       }
    //     });
    //   }, []);

    function openNav() {
        document.getElementById("mySidenav").style.width = "90%";
    }

    var x = window.matchMedia("(max-width: 768px)")

    function closeNav() {
        if (x.matches) {
            document.getElementById("mySidenav").style.width = "0";
        }
    }


    return (

        <>


            <Navbar collapseOnSelect expand="lg" className="custom-nav" variant="dark">
                <Container fluid className="w-100">

         

          <span onClick={(e) => openNav()} className="opennav">
                  <i class="fa fa-bars"></i>
          </span>
                    <div id="mySidenav" className="justify-content-md-around gap-2 sidenav">
                        <a href="javascript:void(0)" class="closebtn" onClick={(e) => closeNav()}>&times;</a>
                        <Link to={"/"} className="logo">
                            <img src={Logo}/>
                        </Link>
                        <Nav>
                            <a href="#about-section" onClick={(e) => closeNav()}>About</a>
                            <a href="#roadmap-section" onClick={(e) => closeNav()}>Roadmap</a>
                            <a href={x.matches ? "#drawing-concept-section" : "#drawing"} onClick={(e) => closeNav()}>Drawing
                                Concept</a>
                            <a href="#team-section" onClick={(e) => closeNav()}>Team</a>
                            <a href="#collection-section" onClick={(e) => closeNav()}>Collections</a>
                            {/*<a href="#faqs-section" onClick={(e) => closeNav()}>FAQ</a>*/}
                            <a href="#joinus-section" onClick={(e) => closeNav()}>News</a>
                            <div className="custom-dropdown">
                                <Link to={"#"} href="#">MINT</Link>
{/*                                <div className="custom-drop-body">
                                    <div className="inner-dropdown">
                                        <Link to={"#"}>Fallen One</Link>
                                        <div className="inner-body">
                                            <Link to={"/public-sale/2"}>Public-Sale</Link>
                                            <Link to={"/private-sale/3"}>Private-Sale</Link>
                                        </div>
                                    </div>
                                    <div className="inner-dropdown">
                                        <Link to={"#"}>Guardian One</Link>
                                        <div className="inner-body">
                                            <Link to={"/public-sale/0"}>Public-Sale</Link>
                                            <Link to={"/private-sale/1"}>Private-Sale</Link>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                            {account == "0x2c0DeDD0218E0414564c9A4Ae7f8B51fc2644e9F" ?
                                <Link to="nft">NFT's</Link> : null}

                        </Nav>

                        <div className="d-flex justify-content-center">
                            <ul className="top-social">
                                <li><a href="https://twitter.com/CAngelsNFT" target="_blank"><i
                                    class="fa-brands fa-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/celestialangelsnft/" target="_blank"><i
                                    class="fa-brands fa-instagram"></i></a></li>
                                <li><a href="" className="img-icon"><img src={opensea}/></a></li>
                            </ul>
                        </div>

                    </div>


                </Container>
            </Navbar>

        </>

    )


}

export default Header;