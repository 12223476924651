import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { injectedConnector } from "../../utils/connectors"
import { connectWallet } from "../../utils/connectWallet";
import Header from "../../components/Header"
import loader from "../../assets/images/loader.gif"
import { Col, Container, Row, Accordion } from "react-bootstrap";

function Admin() {

    const [loaded, setLoaded] = useState(false)

    const {
        connector,
        library,
        account,
        chainId,
        activate,
        deactivate,
        active,
        errorWeb3Modal,
        active: networkActive, error: networkError, activate: activateNetwork
    } = useWeb3React();

    useEffect(() => {
        injectedConnector
            .isAuthorized()
            .then((isAuthorized) => {
                setLoaded(true)
                if (isAuthorized && !networkActive && !networkError) {
                    activateNetwork(injectedConnector)
                }
            })
            .catch(() => {
                setLoaded(true)
            })
    }, [activateNetwork, networkActive, networkError])

    var myVar;

    function myFunction() {
        myVar = setTimeout(showPage, 3000);
    }

    function showPage() {
        document.getElementById("loader").style.display = "none";
        document.getElementById("myDiv").style.display = "block";
    }

    myFunction();
    return (

        <>

            <div id="loader">
                <img src={loader} />
            </div>

            <div style={{ display: "none" }} id="myDiv" class="animate-bottom">

                <Header />

                <section className="section w-100">
                    <Container>
                        
                        <div className="admin-page">
                        {/* <div className="head-btn">
                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">SetNftaddress</button>
                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Start Auction</button>
                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Bid</button>
                        </div> */}
                            <Row >
                                <Col lg={4}>
                                    <h3 className="admin-title">Search Here</h3>
                                    <input className="form-control" placeholder="Enter ID to get NFT" />
                                    <h4 className="mt-2">Public</h4>
                                    <div className="admin-btn-group">
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Fallen One</button>
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Guardian One</button>
                                    </div>
                                    <h4 className="mt-2">Private</h4>
                                    <div className="admin-btn-group">
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Fallen One</button>
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Guardian One</button>
                                    </div>

                                </Col>
                                <Col lg={4}>
                                    <h3 className="admin-title">Result</h3>

                                </Col>
                            </Row>

                        </div>
                        <div className="admin-page">

                            <Row >
                                <Col lg={4}>
                                    <h3 className="admin-title">Search Here</h3>
                                    <input className="form-control" placeholder="Enter address to get NFT" />
                                    <h4 className="mt-2">Public</h4>
                                    
                                    <div className="admin-btn-group">
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Fallen One</button>
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Guardian One</button>
                                    </div>
                                    <h4 className="mt-2">Private</h4>
                                    <div className="admin-btn-group">
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Fallen One</button>
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Guardian One</button>
                                    </div>

                                </Col>
                                <Col lg={4}>
                                    <h3 className="admin-title">Result</h3>

                                </Col>
                            </Row>

                        </div>
                    </Container>
                </section>

                {/* <div id="footer-section" className="position-relative">
                    <Footer />
                </div> */}

            </div>

        </>

    )
}

export default Admin;