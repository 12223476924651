import { Col, Container, Row } from "react-bootstrap";
import about from "../assets/images/about.png";
import aboutVideo from "../assets/images/about-video.mp4";
import about_Video from "../assets/images/about_video.mp4";
import DownArrow from "./DownArrow";

const About = () => {
    return (
        <section className="section2">
            <Row className="mx-0 gap-4 gap-lg-0">

                <Col lg={6} className="ps-0">
                    <div>
                        <div className="about mx-3 ms-md-5 mt-3">

                            <h2 className="section-title">About</h2>


                            <p>
                                Celestial Angels - 4,900 wickedly divine NFTs, transcendent avatars and enigmatic defenders forged as invincible ERC721 tokens upon the cosmic altar of the Ethereum blockchain.<br /><br />

                                Brace yourself as 4,900 unparalleled masterpieces explode into existence through a cataclysmic duality of celestial revelations, embodying the timeless clash between the relentless sentinels (2,450 Guardian Angels) and the enigmatic forsaken (2,450 Fallen Angels).<br /><br />

                                Embark on this exhilarating odyssey by minting a Guardian Angel for free, constrained to one per celestial vessel. Conjure a Guardian to seize the key to summoning a Fallen Angel through an arcane burn mechanism & unrelenting game theory; igniting pulse-pounding gamification possibilities like never before!<br /><br />

                                Yet beware, for the Celestial Angels harbor untold enigmas and cryptic mysteries...<br />
                                Plunge headfirst into the astral abyss and unshackle the cosmic riddles that lie in wait!

                            </p>


                            <a target="_blank" href="https://twitter.com/CAngelsNFT" className="btn btn-primary btn-wallet join-discord-btn"><i class="fa-brands fa-twitter"></i><span>FOLLOW OUR TWITTER</span></a>
                        </div>

                    </div>
                </Col>

                <Col lg={6} className="pe-0 align-self-center">
                    {/* <img src={about} /> */}
                    <video className="mt-5 w-100" src={about_Video} loop={true} autoPlay={true} muted={true} controls={false} playsInline>

                    </video>

                </Col>

            </Row>


            

        </section >
    )
}

export default About;